.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  transition: 200ms;
  box-sizing: border-box;
}

.containerPadding {
  padding: 0 20px;
}

.containerActive {
  position: relative;
  overflow: hidden;
  transform: translateX(280px);
}

.containerActive::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
  opacity: 0.5;
  background-color: #fff;
}

@media (min-width: 728px) {
  .container {
    width: 100%;
  }
}
