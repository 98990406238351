.documents {
  padding: 32px 20px;
  padding-bottom: 96px;
  margin-left: -20px;
  margin-right: -20px;
  border-radius: 16px;
  background-color: #fafafd;

  @media (min-width: 768px) {
    margin-bottom: 24px;
    padding: 32px 31px 32px 33px;
    margin-left: 0;
    margin-right: 0;
  }
}

.name {
  margin-top: 0;
  margin-bottom: 24px;
  color: #000;
  font-size: 15px;
  font-weight: 700;
}

.wrapper {
  display: grid;
  row-gap: 8px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 23px;
    row-gap: 16px;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f1f2f6;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: border-color 0.3s;

  &:hover {
    border-color: #516cfb;

    .title {
      color: #1366f1;
      font-weight: 700;
    }

    .icon {
      opacity: 1;
    }
  }
}

.block {
  display: flex;
  flex-direction: column;
}

.blockRow {
  flex-direction: row;
  align-items: center;

  @media (min-width: 768px) {
    align-items: stretch;
    flex-direction: column;
  }
}

.title {
  font-size: 14px;
  color: #000;
  transition: color 0.3s, font-weight 0.3s;
  margin-right: 4px;

  @media (min-width: 768px) {
    margin-right: 0;
  }
}

.subtitle {
  font-size: 11px;
  color: #000000;
  font-weight: 300;
}

.subheading {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.icon {
  opacity: 0.5;
  transition: opacity 0.3s;
}
