.header {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (min-width: 992px) {
    margin-bottom: 16px;

    .reset {
      display: none;
    }
  }
}

.reset {
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px dashed black;
  text-transform: uppercase;
  cursor: pointer;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  max-width: 182px;
  color: #161616;
}

.filters {
  display: flex;
  flex-direction: row;
  margin-right: -20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-right: 20px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 992px) {
    margin-bottom: 20px;
    padding-right: 0;
    margin-right: 0;
    flex-direction: column;
    overflow-x: visible;
  }
}

.button {
  font-size: 12px;
  box-sizing: border-box;
  color: #5f5f5f;
  outline: none;
  font-weight: 500;
  border: 1px solid #c3c8cc;
  border-radius: 6px;
  padding: 16px;
  background-color: #fff;
  appearance: none;
  font-family: Geometria;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;

  &:hover {
    color: #000;
    background-color: #e4e7ea;
    border-color: rgba(0, 0, 0, 0);
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  @media (min-width: 992px) {
    &:not(:last-child) {
      margin-bottom: 8px;
      margin-right: 0;
    }
  }
}

.active {
  color: #000;
  background-color: #e4e7ea;
  border-color: rgba(0, 0, 0, 0);
}

.bottom {
  display: none;

  @media all and (min-width: 992px) {
    display: block;
    text-align: center;
  }
}
