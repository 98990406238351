.container {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
}

.version {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 30px;
  font-family: Roboto Mono, monospace;
  font-size: 11px;
  text-align: center;
  opacity: 0.2;
}

.logoText {
  margin-top: 40px;
  width: 217px;
}

.heading {
  font-size: 28px;
  margin: 48px 0 0 0;
}

.subheading {
  font-size: 15px;
  margin: 0;
}

.resetPass {
  display: block;
  margin-top: 25px;
  width: 100%;
  color: #343434;
  font-weight: 500;
  text-align: center;
}

.cityBlock {
  position: relative;
}

.cityWrapper {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  z-index: 5;
  transform: translateY(-100%);
}

@media (min-width: 1221px) {
  .container {
    width: 500px;
    margin: 0 auto;
  }
}
