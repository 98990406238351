.button {
  font-family: Geometria, sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  width: 100%;
  height: 56px;
  margin-top: 20px;
  text-align: center;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 9px 27px -3px rgba(21, 86, 43, 0.27);
  background-color: #2dd172;
  transition: filter ease 0.14s;
  &:hover,
  &:focus {
    filter: brightness(1.15);
  }
}

.buttonBlue {
  composes: button;
  background-color: #002143;
}

.buttonRed {
  composes: button;
  background-color: #d12d2d;
}

.buttonPrimary {
  composes: button;
  background-color: #fff;
  color: #5f5f5f;
  font-size: 13px;
  box-shadow: none;
  border: 1px solid #c3c8cc;
  border-radius: 5px;
  height: 40px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #2dd172;
    color: #ffffff;
    border: 1px solid transparent;
  }
}
