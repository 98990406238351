.modal {
  @media (min-width: 1300px) {
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.17);
    background-color: #fff;
  }
}

.important {
  margin-top: 0;
  font-size: 14px;
  font-weight: 500;
  color: #ce3b3b;
  text-transform: uppercase;
  opacity: 0.5;
  margin-bottom: 12px;

  @media (min-width: 1300px) {
    display: none;
  }
}

.top {
  @media (min-width: 1300px) {
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.text {
  margin-bottom: 16px;
  padding: 15px 21px 15px 13px;
  font-size: 15px;
  color: #000;
  margin-top: 0;
  border-radius: 8px;
  background-color: #fafafd;
}

.bold {
  font-weight: bold;
  color: #ce3b3b;
}

.title {
  font-size: 16px;
  color: #000;
  margin-top: 0;
  margin-bottom: 12px;
}

.help {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: #000;

  @media (min-width: 1300px) {
    border: 1px solid #e4e7ea;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.helpTitle {
  font-size: 14px;
  margin-bottom: 6px;
}

.helpText {
  font-size: 13px;
}

.helpUnderline {
  text-decoration: underline;
  color: #000;
}

.email {
  margin-bottom: 32px;
  border-radius: 8px;
  background-color: #fafafd;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emailIcon {
  cursor: pointer;
}

.emailName {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
