.container {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  max-width: 9999999px;
  margin: 0 -20px;
  padding: 0 20px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1221px) {
    margin-right: -120px;
  }
}

.slideItem {
  width: 120px;
  height: 160px;
  border-radius: 8px;
  object-fit: cover;
}

.imageContainer {
  font-size: 0;
  position: relative;
  margin: 0 6px;
}

.imageContainer::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(8, 19, 59, 0.22),
    rgba(16, 31, 59, 0.51) 99%
  );
}

.imageContainer:first-of-type {
  margin-left: 0;
}
.container::after {
  content: "";
  display: block;
  min-width: 5px;
}
