.row {
  display: flex;

  &:nth-of-type(2n) {
    span:nth-of-type(2n) {
      background-color: rgb(187, 187, 187);
    }
    span:nth-of-type(2n-1) {
      background-color: rgb(233, 233, 233);
    }
  }
  &:nth-of-type(2n - 1) {
    span:nth-of-type(2n -1) {
      background-color: rgb(187, 187, 187);
    }
    span:nth-of-type(2n) {
      background-color: rgb(233, 233, 233);
    }
  }
  span {
    flex: 1 0;
    padding: 5px;
    min-width: 120px;
  }
}

.link {
  position: relative;
  h2 {
    font-size: 20px;
  }
  transition: all ease .18s;
}

.active {
  background-color: #e7e7e7;
  padding: 0 16px;
  border-radius: 8px;
}
