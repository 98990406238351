.referalList {
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 478px) {
    width: 100%;
  }
}

.copy {
  position: relative;

  &:hover, &:focus {
    img {
      animation: .4s linear pop;
    }
  } 

  &:active {
    img {
      animation: .4s linear pop;
    }
  }
  input {
    padding-right: 70px;
  }
  img {
    position: absolute;
    right: 20px;
    bottom: 17px;
    cursor: pointer;
  }
}

@keyframes pop {
  to {
    transform: scale(2);
    opacity: 1;
  }
}

.referalCard {
  padding: 20px;
  max-width: 300px;
  background-color: #f5f5f5;
  border-radius: 15px;
  margin-bottom: 20px;
  overflow: hidden;

  &:nth-of-type(n+1) {
    margin-right: 15px;
  }

  @media (max-width: 478px) {
    width: 100%;
    margin-right: 0 !important;
  }
  h4 {
    margin: 0;
  }

  div {
    margin-top: 15px;
  }
}

.p {
  font-size: 12px;
  color: black;
}