.figure {
  margin: 0;
  position: relative;
  z-index: 2;
}

.address {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 5;
  font-size: 9px;
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #fff;
  color: #002143;

  @media (min-width: 992px) {
    left: 32px;
    transform: translateY(50%);
  }
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 56px;
  height: 56px;

  transition: 0.3;

  &:hover {
    circle {
      fill-opacity: 0.7;
    }
  }

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }
}

.image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.content {
  position: relative;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #fafafd;
  padding: 30px 20px 40px;
  transform: translateY(-6px);
  z-index: 1;
  margin-bottom: 24px;
  margin-left: -20px;
  margin-right: -20px;

  @media (min-width: 768px) {
    padding: 30px 38px 32px 31px;
    margin-left: 0;
    margin-right: 0;
  }
}

.top {
  margin-top: 0;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.ceo,
.name {
  font-size: 15px;
  font-weight: bold;
  color: #000;
}

.ceo {
  font-weight: 400;
}

.text {
  font-weight: 300;
  color: #64778c;
  font-size: 13px;
  line-height: normal;

  @media (min-width: 768px) {
    color: #000;
    font-size: 14px;
  }
}
