.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: logoAnim 1.5s ease-in-out infinite;
}

.container2 {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (min-width: 1221px) {
    width: 500px;
    margin: 0 auto;
    align-items: center;
  }
  h1 {
    font-size: 21px;
    text-transform: unset;
  }

  button {
    margin-bottom: 54px;
  }

  p {
    font-size: 14px;
    margin: 0;

    &:nth-of-type(2) {
      font-size: 16px;
      color: black;
      font-weight: 500;
    }
  }

  a {
    margin-top: 14px;
    font-size: 14px;
    text-decoration: underline;
    font-weight: bold;
    color: #2dd172;
  }
}

.container2 button {
  width: 150px;
}

.img {
  height: 130px;
  border-radius: 24px;
  box-shadow: 0 0 34px 0 rgba(153, 153, 153, 0.13);
}

h1 {
  text-transform: uppercase;
}

@keyframes logoAnim {
  50% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}
