.input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-family: Geometria, sans-serif;
  font-size: 15px;
  margin-top: 10px;
  padding: 18px 15px;
  box-shadow: 0 0 34px 0 rgba(153, 153, 153, 0.13);
  border: 0;
  border-radius: 8px;
}

.inputError {
  composes: input;
  border: 1px solid #c70000;
  box-shadow: 0 0 34px 0 rgba(255, 0, 0, 0.13);
}

.input:active, .input:focus {
  outline: none;
  box-shadow: 0 0 5px 1px #73b8ff;
  border-radius: 8px;
}

.label {
  display: block;
  margin-top: 24px;
  font-family: Geometria, sans-serif;
  font-size: 16px;
  font-weight: 500;
}