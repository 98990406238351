.headingRow {
  display: block;
}

:global(.no-gap).headingRow {
  margin: 0;
}

.heading {
  display: block;
  margin-bottom: 0;
  margin-top: 0;
}

.subheading {
  display: block;
  color: #a7a7a7;
  font-size: 12px;
}

.subheading p {
  margin-top: 7px;
}
