.container {
  //margin-top: 20px;
}

.container button {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  font-size: 0;
  background-color: transparent;
  box-shadow: 0 0 29px -4px rgba(57, 69, 134, 0.17);
  border: 0;
  border-radius: 8px;
  z-index: 9;
}

button span {
  display: block;
  width: 30px;
  height: 3px;
  margin-right: 0;
  background-color: #002143;
}

button span:first-child {
  margin-bottom: 5px;
}

@media (min-width: 1221px) {
  .container {
    display: none;
  }
}
