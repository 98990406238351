.layout {
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  align-items: flex-start;
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1221px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: 1320px) {
    padding-left: 0;
    padding-right: 0;
  }
}
