.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

h3 {
  margin: 0;
  font-size: 13px;
  opacity: .5;
  color: #002143;
}

a {
  text-decoration: none;
}

a > img:active, a > img:focus {
  transition: transform ease .2;
  transform: scale(.85);
}