.modal {
  // position: absolute;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  // top: 50%;
  // // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  // transform: translate(-50%, -50%);
  margin: auto;
  outline: none;
  box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.17);
  background-color: #fff;
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    display: block;
    height: auto;
    min-height: auto;
    width: 434px;
    border-radius: 16px;
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 90;

  @media (min-width: 576px) {
    padding-top: 70px;
    padding-bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
