.listContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    display: inline-flex;
    font-size: 14px;
    text-transform: initial;

    img {
      margin-left: 8px;
      width: 18px;
      object-fit: cover;
      transform: rotate(90deg);
      transition: transform ease .140s;
      cursor: pointer;

      &.active {
        transform: rotate(-90deg);
      }
    }
  }
}
.itemContainer {
  /* flex: 1 0; */
  width: 100%;
  margin: 15px 0;
  background-color: #e8e8ec;
  padding: 10px 20px;
  border-radius: 15px;
  &.passed h5 {
    color: #47f790;
  }

  &.started h5 {
    color: #e48c19;
  }
  h5 {
    opacity: 1;
    font-size: 18px;
    margin: 9px 0;
  }
}

.itemContainer dd,
.itemContainer dt {
  font-size: 100%;
}
.buttons {
  display: flex;
  margin: 0 -20px -10px -20px;
  justify-content: space-between;

  button:first-of-type {
    border-radius: 0;
    border-bottom-left-radius: 8px;
  }
  button:last-of-type {
    border-radius: 0;
    border-bottom-right-radius: 8px;
  }
}

.user_fields {
  display: flex;
  flex-flow: row wrap;
  div.error {
    background-color: rgba(255, 91, 91, 0.336) !important;
  }
  div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 4px 8px;
    margin: 0 -8px;
    margin-bottom: 4px;

    &:nth-of-type(2n) {
      background-color: #ffffffb9;
    }
    label {
      font-weight: 400;
      line-height: 16px;
      opacity: 0.7;
      &::after {
        content: ': ';
      }
    }

    input {
      min-width: 70%;
      font-weight: 500;
      padding: 0;
      display: block;
      line-height: 16px;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}

.tabs {
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  button {
    border-radius: 8px;
    border: none;
    margin: 4px;
    padding: 10px;
    background-color: #f0f0f4;
    cursor: pointer;
    &.active {
      background-color: #002143;
      color: #fff;
      font-weight: 500;
    }
  }
}

.images {
  display: flex;
  justify-content: space-around;
  margin: -10px -20px 0 -20px;
  a {
    position: relative;
    width: 33%;

    &:hover img {
      position: absolute;
      left: 300%;
      transform: scale(4);
      z-index: 3;
    }
    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      transition: left ease 0.5s;
    }

    &:first-of-type img {
      border-radius: 8px 0 0 0;
    }
    &:last-of-type img {
      border-radius: 0 8px 0 0;
    }
  }
}
