.wrapper {
  position: relative;
}

.block {
  border-radius: 12px;
  box-shadow: 0 12px 74px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  padding: 32px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  transform: translateY(-56px);

  @media (min-width: 768px) {
    padding: 32px 32px 24px 25px;
    transform: translateY(0);
    position: absolute;
    left: 0;
    right: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.left {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.price {
  display: inline-block;
  padding: 11px 14px;
  border-radius: 10px;
  background-color: rgba(236, 237, 240, 0.5);
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin-bottom: 8px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}

.priceBold {
  font-weight: 700;
}

.priceBuy {
  padding-left: 14px;
  font-size: 11px;
  font-weight: 500;
  color: #000;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
}

.investButton {
  padding: 16px 71px;
  appearance: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 7px;
  box-shadow: 0 9px 27px -3px rgba(21, 86, 43, 0.27);
  background-color: #2dd172;
  font-family: 'Geometria';
  outline: none;
  border: none;

  &:disabled {
    color: #747474;
    background-color: #b8b8b8;
  }
}
