.col2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col2 > label {
  font-size: 13px;
  width: 48%;
}

.popup {
  position: absolute;
  border-radius: 15px 15px 0 0;
  background-color: #E4E7EA;
  padding: 32px 24px;
  z-index: 9;
  bottom: 0;
}

.popup p.blue {
  display: block;
  width: auto;
  padding: 10px 15px;
  background-color: #002143;
  font-weight: 500;
  color: #fff;
  border-radius: 8px;
}

.entering {
  transform: translateY(100%);
  opacity: 0;
}

.entered {
  opacity: 1;
  transform: none;
  transition: 333ms ease-out;
  will-change: transform, opacity;
}

.exit {
  transform: translateY(0px);
  opacity: 1;
}

.exiting {
  opacity: 0;
  transform: translateY(400px);
  transition: 333ms ease-in;
  will-change: transform, opacity;
}

.popup h4 {
  font-size: 18px;
  margin: 0;
}

.popup p {
  font-size: 14px;
  width: 80%;
}

@media (min-width: 1221px) {
  .container {
    width: 500px;
    margin: 0 auto;
  }
}