.steps {
  display: flex;
  justify-content: center;
  padding-bottom: 48px;
}

.step {
  height: 5px;
  border-radius: 5px;
  width: 50px;
  background-color: #c3c8cc;
  transition: background-color 0.3s;

  &:not(:last-child) {
    margin-right: 24px;
  }
}

.stepActive {
  background-color: #516cfb;
}
