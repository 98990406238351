.link {
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  color: rgba(22, 22, 22, 0.5);
  transition: color 0.3s;
}

.link:hover {
  color: #2dd172;
}

.navigation {
  padding: 18px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  border-radius: 8px;
  transition: color 0.3s;

  &:hover {
    color: #2dd172;
  }
}

.button {
  display: block;
  font-family: Geometria, sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  width: 100%;
  height: 56px;
  margin-top: 20px;
  text-align: center;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 9px 27px -3px rgba(21, 86, 43, 0.27);
  background-color: #2dd172;
}

a.button {
  line-height: 56px;
}

.buttonBlue {
  composes: button;
  background-color: #002143;
}
