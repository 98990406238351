.navigation {
  border-radius: 12px;
}

.containerMobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 32px;
  padding-bottom: 24px;
  transform: translateX(-110%);
  z-index: 9;
  transition: 200ms;
  overflow-y: auto;
}

.containerMobile .navLinkLast,
.containerMobile .navLink,
.containerMobile .expandButton {
  color: #000;
}

.containerMobile .navLinkLast {
  margin-bottom: 56px;
}

.containerMobile .navLink {
  margin-bottom: 26px;
}

.containerMobile .support {
  color: #161616;
  font-weight: 300;
}

.containerPc {
  display: none;
  background-color: #f0f0f4;
  padding: 56px 32px 48px 32px;
  width: 302px;
  box-sizing: border-box;
  margin-right: 38px;
}

.containerActive {
  transform: translateX(0);
}

.expandButton {
  width: 100%;
  font-family: Geometria;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  appearance: none;
  border: none;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  color: rgba(22, 22, 22, 0.5);
  transition: color 0.3s;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);

  &:hover {
    color: #2dd172;
  }

  &:focus {
  }

  span {
    width: auto;
    height: auto;
    margin-bottom: 0 !important;
    background-color: transparent;
  }
}

.expandIcon {
  margin-left: 6px;
  width: 18px;
  height: 18px;
  transition: transform 0.3s;
}

.expandActive {
  color: #161616;

  .expandIcon {
    transform: rotate(180deg);
  }
}

.submenu {
  margin-top: 13px;
  display: flex;
  flex-direction: column;
}

.sublink {
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
}

.navLinkFirst {
  margin-bottom: 16px;
}

.navLink {
  margin-bottom: 33px;
}

.navLinkLast {
  margin-bottom: 160px;
}

.support {
  font-size: 11px;
}

.logo {
  width: 150px;
  margin-bottom: 40px;
}

@media (min-width: 1221px) {
  .containerMobile {
    display: none;
  }
  .containerPc {
    display: inline-flex;
    flex-flow: column nowrap;
    flex-shrink: 0;
  }
}
