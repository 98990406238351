.wrapper {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 34px 0 rgba(153, 153, 153, 0.13);
  display: none;
  height: auto;
  max-height: 174px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c3c8cc;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
  }
}

.opened {
  display: block;
}

.city {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #30323d;
  transition: color 0.3s;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &:hover {
    color: #2dd172;
  }
}
