.link {
  margin-bottom: 16px;
}

.telegram {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 12px 35px 0 rgba(0, 0, 0, 0.06);
  background-color: #f8f8fb;
  margin-bottom: 16px;
}

.telegramImage {
  width: 51px;
  height: 64px;
  margin-right: 17px;
}

.telegramName {
  font-size: 15px;
  line-height: 1.07;
  font-weight: 500;
  width: 140px;
  color: #1366f1;
}

.telegramBold {
  font-weight: bold;
}

.account {
  display: flex;
  flex-direction: column;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 12px 35px 0 rgba(0, 0, 0, 0.06);
  background-color: #f8f8fb;
  margin-bottom: 16px;
}

.accountTitle {
  font-size: 11px;
  color: rgba(22, 22, 22, 0.5);
  margin-bottom: 4px;
}

.accountEmail {
  font-size: 14px;
  color: #161616;
  margin-bottom: 12px;
}

.accountKyc {
  font-size: 12px;
}

.accountStatus {
  color: #d12d2d;
  font-weight: 700;
}

.accountActive {
  color: #2dd172;
}

.metamask {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 12px 35px 0 rgba(0, 0, 0, 0.06);
  background-color: #f8f8fb;
  margin-bottom: 16px;
}

.metamaskActive .metamaskIcon {
  background-color: #2dd172;
}

.metamaskStatus {
  font-size: 14px;
  color: #161616;
  text-transform: uppercase;
  font-weight: 400;
  font-family: Roboto Mono, monospace;
  margin-right: 7px;
}

.metamaskIcon {
  width: 14px;
  height: 14px;
  background-color: #d12d2d;
  border-radius: 50%;
}

.metamaskIconActive {
  width: 14px;
  height: 14px;
  background-color: #2dd172;
  border-radius: 50%;
}

.bottom {
  text-align: center;
}

.sign_out {
  appearance: none;
  outline: none;
  border: none;
  color: black;
  cursor: pointer;
  background-color: #fafafd;
  padding: 13px 27px;
  opacity: 0.75;
  border-radius: 8px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}
