.upheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  @media (min-width: 768px) {
    padding-left: 48px;
    // display: none;
  }
  // @media (max-width: 768px) {
  //     // top: 10px;
  //     padding-top: 48px;
  //   }
  > img {
    position: absolute;
    cursor: pointer;
    left: 40px;
    width: 20px;
    transform: rotate(180deg);
    @media (max-width: 768px) {
      padding: 8px 12px;
      border-radius: 8px;
      box-shadow: 0 0 29px -4px rgba(57, 69, 134, 0.17);
      top: 0px;
      left: 60px;
      width: 24px;
    }
  }
}

.mobileHidden {
  display: none;

  @media (min-width: 768px) {
    display: inline;
  }
}
