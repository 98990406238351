.header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 24px;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 37px;
  }

  @media (min-width: 1221px) {
    margin-top: 0;
  }
}

.headerLeft {
  margin-right: 10px;
  margin-bottom: 16px;
}

.headerRight {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 992px) {
    flex-direction: row;
  }
}

.infoLink {
  &:last-child {
    margin-bottom: 12px;
  }

  @media (min-width: 992px) {
    &:last-child {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.blackBox {
  margin-top: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 20px 24px;
  background-color: #30323d;
  border-radius: 8px;
  color: #fff;

  h2 {
    color: #fff;
    font-size: 15px;
    margin: 0 0 8px 0;
  }

  p {
    color: #fff;
    font-size: 13px;
    opacity: 0.75;
    margin: 0;
  }

  .boxButton {
    display: block;
    height: auto;
    width: auto;
    background-color: transparent;
    border: none;
    padding: 0;

    img {
      height: 100%;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  @media all and (min-width: 992px) {
    flex-direction: row;
  }
}

.sidebar {
  flex-shrink: 0;
  order: -1;
  margin-bottom: 24px;

  @media all and (min-width: 992px) {
    order: 1;
    margin-left: 42px;
    width: 220px;
  }
}
