.wrapper {
  display: flex;
}

.main {
  flex-grow: 1;

  @media (min-width: 1221px) {
    margin-right: 40px;
  }
}

.sidebar {
  display: none;

  @media (min-width: 1221px) {
    display: flex;
    flex-shrink: 0;
    width: 196px;
  }
}

//   .popupMain {
//     // top: -62px;
//     // left: 0;
//     // width: 100%;
//     // height: 100vh;
//     // overflow: auto;
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: calc(100vw - 40px);
//     height: 100vh;
//     overflow: auto;
//   }

//   .popupText {
//     font-size: 13px;
//   }

//   .contentRight > .imageContainer > img {
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// }

// .spinner,
// .spinner:after {
//   border-radius: 50%;
//   width: 2.5em;
//   height: 2.5em;
// }
// .spinner {
//   font-size: 10px;
//   position: relative;
//   margin: 50% auto;
//   text-indent: -9999em;
//   border-top: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-right: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
//   border-left: 1.1em solid #ffffff;
//   -webkit-animation: load8 1.1s infinite linear;
//   animation: load8 1.1s infinite linear;
// }
// @-webkit-keyframes load8 {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
// @keyframes load8 {
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// }
