.main {
  border-radius: 12px;
  background-color: transparent;
  padding-top: 20px;

  @media (min-width: 768px) {
    margin-bottom: 70px;
    padding-bottom: 73px;
    padding: 32px 40px 73px 40px;
  }

  @media (min-width: 1221px) {
    box-shadow: 0 12px 74px 0 rgba(0, 0, 0, 0.09);
    background-color: #fff;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 18px;
  align-items: center;
  margin-bottom: 32px;

  @media all and (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 0;
  }
}

.infoFirst {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
}

.infoSecond {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.infoBlock {
  display: inline-block;
}

.infoGroup {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  background-color: #fafafd;
  padding: 10px;
}

.info dd,
.info dt {
  font-family: Geometria, sans-serif;
  font-size: 10px;
  display: block;
  text-align: center;
  color: #002143;
}

.info dd {
  color: #c3c8cc;
  opacity: 1;
  margin-right: 0;
}

.info dt {
  margin-top: 5px;
  font-weight: 500;
  font-size: 14px;
}

.smallText {
  font-size: 8px;
}

.largeText {
  font-size: 16px;
}

.ratingText {
  font-size: 17px;
  font-weight: 500;
  margin-top: 8px !important;
}

.greenText {
  color: #1b9445;
}

.grayText {
  color: #002143;
  font-weight: 100;
}

.progressBlock {
  margin-bottom: 32px;
}

.progress {
  margin-top: 0;
  height: 13px;
  border-radius: 6.5px;
  background-color: #c3c8cc;
  margin-bottom: 14px;
}

.progress[value]::-webkit-progress-value {
  height: 13px;
  border-radius: 6.5px;
  box-shadow: 0 -3px 18px -2px rgba(25, 144, 75, 0.46);
  background-color: #2fdc6b;
}

.progressValue {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #e4e7ea;
  padding-bottom: 22px;

  @media (min-width: 768px) {
    border-bottom: none;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.progressName {
  font-size: 10px;

  color: #c3c8cc;
}

.progressStatus {
  font-size: 13px;
  color: #2fdc6b;
  font-weight: bold;
}

.progressMaxValue {
  color: #c3c8cc;
}

.progressSlash {
  color: #e4e7ea;
}

.mobileHidden {
  display: none;

  @media (min-width: 768px) {
    display: inline;
  }
}
