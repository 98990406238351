.block {
  padding: 32px 20px 0 20px;
  font-family: 'Geometria';
  flex-grow: 1;

  @media (min-width: 576px) {
    padding: 28px 24px 0 24px;
  }
}

.blockPadding {
  padding-bottom: 0;
}

.top {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (min-width: 576px) {
    margin-bottom: 24px;
  }
}

.step {
  font-size: 12px;
  opacity: 0.5;
  font-weight: 500;
  color: #5f5f5f;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.name {
  font-size: 16px;
  color: #000;
}

.summ {
  color: #fff;
  text-align: center;
  font-size: 21px;
  margin-bottom: 32px;

  span {
    font-size: 26px;
    font-weight: 500;
    &::after {
      content: '$';
    }
  }
}

.choices {
  display: flex;
  margin-bottom: 48px;

  @media (min-width: 576px) {
    margin-bottom: 32px;
  }
}

.choiceButton {
  padding-top: 22px;
  padding-bottom: 22px;
  border: 2px solid #c3c8cc;
  border-radius: 8px;
  outline: none;
  flex-grow: 1;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  color: #5f5f5f;
  font-family: Geometria;
  appearance: none;
  transition: border-color 0.3s, color 0.3s;

  &:first-child {
    margin-right: 8px;
  }

  @media (min-width: 576px) {
    font-size: 18px;
    padding-top: 29px;
    padding-bottom: 29px;

    &:first-child {
      margin-right: 16px;
    }
  }
}

.choiceButtonActive {
  border-color: #2dd172;
  color: #000;
}

.button {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  font-family: Geometria;
  appearance: none;
  outline: none;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 9px 27px -3px rgba(21, 86, 43, 0.27);
  background-color: #2dd172;
  outline: none;
  border: none;
  width: 100%;
}

.buttonHidden {
  @media (min-width: 576px) {
    display: none;
  }
}

.rejectButton {
  margin-top: 16px;
  background-color: #5f5f5f;
}

.steps {
  margin-top: 48px;
}

.confirmation {
  padding: 13px 15px 13px 13px;
  border-radius: 8px;
  background-color: #fafafd;
  margin-bottom: 48px;

  @media (min-width: 576px) {
    padding: 24px 16px;
    margin-bottom: 24px;
  }
}

.confirmationText {
  margin: 0;
  font-size: 13px;
  color: #000;
}

.qrcodeWrapper {
  margin-bottom: 26px;

  @media (min-width: 576px) {
    margin-bottom: 16px;
  }
}

.qrcode {
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 8px;
  background-color: #fafafd;
  text-align: center;
  display: block;
}

.image {
  height: 200px;
  width: 200px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.sumText {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 16px;
}

.sumGreen {
  color: #2dd172;
}

.sumDesc {
  margin: 0;
  font-size: 13px;
  font-weight: 300;
  color: #5f5f5f;
}

.stepName {
  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
}

.important {
  display: flex;
  flex-direction: column;
}

.importantText {
  font-size: 14px;
  font-weight: 500;
  color: #ce3b3b;
  text-transform: uppercase;
  margin-top: 12px;

  @media (min-width: 576px) {
    display: none;
  }
}

.camera {
  font-size: 12px;
  font-weight: 500;
  color: #5f5f5f;
  display: flex;
  align-items: center;
  margin-top: 28px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
}

.exclameIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}

.link {
  font-size: 13px;
  font-weight: 500;
  color: #2dd172;
  margin-top: 8px;
  text-decoration: underline;

  @media (min-width: 576px) {
    font-size: 15px;
  }
}

.title {
  opacity: 0.5;
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 15px;
  color: #252525;
}

.info {
  padding: 20px 20px 27px 20px;
  border-radius: 8px;
  background-color: #e4e7ea;
  margin-bottom: 24px;

  @media (min-width: 576px) {
    padding: 27px 20px;
  }
}

.infoName {
  margin-top: 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #000;
}

.infoSubname {
  color: #a7a7a7;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 20px;
}

.infoTags {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.infoTag {
  padding: 4px 8px;
  border-radius: 2px;
  background-color: #f5f5f5;
  font-size: 12px;
  color: #002143;
}

.infoDate {
  font-size: 12px;
  font-weight: 400;
  color: rgba(30, 30, 30, 0.7);
}

.infoDateName {
  color: rgba(30, 30, 30, 0.25);
}

.infoFirstName {
  font-size: 13px;
  font-weight: 300;
  color: rgba(37, 37, 37, 0.5);
}

.infoDesc {
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 15px;
  line-height: 1.53;
  color: #252525;
}

.footer {
  padding-top: 32px;
  padding-left: 9px;
  padding-right: 9px;
  background-color: #161616;
  margin-left: -20px;
  margin-right: -20px;
  border-radius: 16px;

  @media (min-width: 576px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-left: -24px;
    margin-right: -24px;
  }
}

.footerPrice {
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 14px;
}

.footerDescription {
  font-size: 13px;
  color: #fff;
  margin-top: 0;
  text-align: center;
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;

  @media (min-width: 576px) {
    max-width: none;
    margin-bottom: 20px;
  }
}

.footerButtons {
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
}

.footerWrapper {
  display: flex;
  margin-bottom: 24px;
}

.footerInput {
  margin-left: 5px;
  margin-right: 5px;
  outline: none;
  border: none;
  border-radius: 8px;
  appearance: none;
  font-size: 28px;
  font-weight: 600;
  // letter-spacing: 2px;
  color: #002143;
  text-align: center;
  width: 100%;
  background: #fff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.footerButton {
  height: 53px;
  width: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  color: #002143;
  font-size: 28px;
  flex-shrink: 0;
}

.footerDisabledButton {
  opacity: 0.4;
}

.footerRemoveButton {
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(138, 138, 138, 0.1);
  color: #fff;
}

.support {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  padding-top: 32px;
  padding-bottom: 42px;
  background-color: #fff;
  display: block;
  text-align: center;
  text-decoration: underline;

  @media (min-width: 576px) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 150%);
    background-color: rgba(0, 0, 0, 0);
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 17px;
  }
}

.importantBlock {
  margin-top: 32px;

  @media (min-width: 1300px) {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(15px, -65%);
    background-color: #fff;
    width: 402px;
  }
}
