.sidebar {
  position: relative;
  height: 308px;
  border-radius: 12px;
  box-shadow: 0 12px 74px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center {
  text-align: center;
}

.title {
  font-size: 14px;
  font-weight: 500;
  color: #30323d;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.subtitle {
  font-size: 10px;
  opacity: 0.5;
  color: #000;
  text-transform: uppercase;
}

.linkMore {
  position: absolute;
  bottom: 24px;
  left: 50%;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  transform: translateX(-50%);
  color: #2dd172;
}
