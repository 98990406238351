.link {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border-radius: 8px;
  background-color: #fafafd;
  transition: opacity 0.5;

  &:hover {
    opacity: 0.5;
  }

  @media (min-width: 992px) {
    padding: 16px;
  }
}

.icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 6px;

  @media (min-width: 992px) {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.name {
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  color: #5f5f5f;

  @media (min-width: 992px) {
    font-size: 14px;
  }
}
