.header {
  padding: 32px 20px 16px 20px;
  border-bottom: 1px solid #e4e7ea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Geometria';
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 20;

  @media (min-width: 576px) {
    background-color: rgba(0, 0, 0, 0);
    position: static;
    padding: 16px 24px;
  }
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #000;

  @media (min-width: 576px) {
    font-size: 22px;
  }
}

.icon {
  cursor: pointer;
}
