.container {
  /* height: 320px; */
  box-shadow: 0 8px 38px -11px rgba(0, 0, 0, 0.29);
  border-radius: 0 0 8px 8px;
  margin-bottom: 20px;
  position: relative;
}

.wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.image {
  height: 165px;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.imageContainer {
  font-size: 0;
}

.imageContainer::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(8, 19, 59, 0.22),
    rgba(16, 31, 59, 0.51) 99%
  );
}

.content {
  padding: 16px 14px 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.contentBody {
  margin-top: auto;
}

.content p:first-child {
  line-height: normal;
  margin-bottom: 16px;
  margin-top: 0;
}

.titleBlock {
  position: absolute;
  display: block;
  bottom: 18px;
  left: 14px;
  filter: grayscale(1);
}

.titleBlock h4 {
  /* mix-blend-mode: difference; */
  font-weight: 500;
  filter: invert(1);
  margin-bottom: 0;
}

.titleBlock dd,
.titleBlock dt {
  display: inline-block;
  color: white;
  font-size: 10px;
  font-family: 'Roboto Mono';
}

.titleBlock dd {
  margin-right: 5px;
  opacity: 0.25;
}

progress {
  height: 9px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  background-color: #e4e7ea;
  margin-bottom: 9px;
  margin-top: 15px;
}

progress::-webkit-progress-bar {
  background-color: #e4e7ea;
  border: 0;
  border-radius: 5px;
}

progress::-webkit-progress-value {
  border: 0;
  border-radius: 5px;
  background-color: #2fdc6b;
}

.progressCount {
  text-align: center;
  opacity: 0.5;
  font-size: 10px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .cardsContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .cardsContainer > .container {
    width: 48%;
  }
}
