.heading {
  margin-bottom: 16px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 22px;

  border-bottom: 1px solid #e4e7ea;

  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border-bottom: none;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 23px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 12px;
  color: #a7a7a7;
  margin-top: 0;
  margin-bottom: 0;
}
