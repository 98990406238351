@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@font-face {
  font-family: 'Geometria';
  src: url('./assets/fonts/Geometria.eot') format('eot'),
    url('./assets/fonts/Geometria.ttf') format('truetype'),
    url('./assets/fonts/Geometria.woff') format('woff');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Geometria';
  src: url('./assets/fonts/Geometria-Bold.eot') format('eot'),
    url('./assets/fonts/Geometria-Bold.ttf') format('truetype'),
    url('./assets/fonts/Geometria-Bold.woff') format('woff');
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: 'Geometria';
  src: url('./assets/fonts/Geometria-Medium.eot') format('eot'),
    url('./assets/fonts/Geometria-Medium.ttf') format('truetype'),
    url('./assets/fonts/Geometria-Medium.eot') format('woff');
  font-weight: 500;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Geometria', sans-serif;
  font-weight: bold;
  color: #002143;
}

a {
  font-family: Geometria, sans-serif;
}

h1 {
  font-size: 24px;
}

p {
  font-family: 'Geometria', sans-serif;
  font-size: 12px;
  color: #5f5f5f;
}

button {
  cursor: pointer;
}

dd,
dt {
  margin: 0;
}

.no-gap {
  margin: 0;
}

input {
  font-size: 16px;
  -webkit-appearance: none;
}

input:active,
input:focus,
button:active,
button:focus {
  outline: none;
  box-shadow: 0 0 5px 1px #73b8ff;
  border-radius: 8px;
}

dd,
dt {
  display: inline-block;
  color: #1e1e1e;
  font-size: 10px;
  font-family: 'Roboto Mono';
}

dd {
  margin-right: 5px;
  opacity: 0.25;
}

ul {
  padding: 0;
}

small {
  font-size: 12px;
  color: #a7a7a7;
}

hr {
  height: 1px;
  background-color: #e4e7ea;
  border: 0;
  width: auto;
  margin: 20px -20px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
